import React, { useEffect, useRef, useState } from "react";
import Body from "../../components/Body";
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import Style from "../../styles/home.module.scss";
import FormStyle from  "../../styles/field.module.scss"
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addGroupOutletSchema } from "../validationSchemas";
import { addDealerGroupOutletAPI, editDealerGroupOutletAPI } from "../../store/dealerGroup/addOutlet";
// import { stateList } from "../../jsonData/state";
import { getCitiesAPI } from "../../store/cities/cities";
import { getGroupDealerApi } from "../../store/dealerGroup/getGroupDealer";
import { outletType, subOType } from "../../jsonData/outletType";
import { getGroupOutletAPI } from "../../store/dealerGroup/getGroupOutlet";
import { showToast } from "../../toast/toast";
import { convertDateFunctionYMD } from "../../utils/dateFormater";
import { facilityCategoryList } from "../../jsonData/facilityCategory";
import { getRegionForOutletApi, getRegionForOutletReducer } from "../../store/dealerGroup/getRegionForOutlet";
import { getStateForOutletApi, getStateForOutletReducer } from "../../store/dealerGroup/getStateForOutlet";
import { getCitiesForOutletApi, getCitiesForOutletReducer } from "../../store/dealerGroup/getCitiesForOutlet";
import { getClusterForOutletApi, getClustersForOutletReducer } from "../../store/dealerGroup/getClusterForOutlet";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
function AddDealorOutlet() {
    const getApplicant = useSelector((state) => state?.getGroupOutletSliceReducer?.result);
    const addOutletDataRedux = useSelector((state) => state?.addDealerGroupOutletReducer);
    const outletTypeList = useSelector((state) => state?.outletTypeReducer?.result);
    const facilityTypeList = useSelector((state) => state?.facilityTypeReducer?.result);
    const isInitialRender = useRef(true);
    const { loading } = addOutletDataRedux;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //const indianStates = stateList;
    const [addGroupOutletRequest, setAddGroupOutletRequest] = useState(getApplicant ? getApplicant : []);
    
    //Lease Dates
    const [leaseExpiryDateShowRoom, setLeaseExpiryDateShowRoom] = useState(null);
    const [leaseExpiryDateWorkShop, setLeaseExpiryDateWorkShop] = useState(null);

    //Appointment date
    const [appointmentDate, setAppointmentDate] = useState(null);

    //closure date
    const [closureDateShowroom, setClosureDateShowroom] = useState(null);
    const [closureDateWorkshop, setClosureDateWorkshop] = useState(null);

    //Operation start and end date
    const [startOfOperationTargetDateShowroom, setStartOfOperationTargetDateShowroom] = useState(null);
    const [startOfOperationTargetDateWorkshop, setStartOfOperationTargetDateWorkshop] = useState(null);

     //LOA and LOI date
     const [LOADate, setLOADate] = useState(null);
     const [LOIDate, setLOIDate] = useState(null);

    //LOA and LOI date
    const [DADate, setDADate] = useState(null);
    const [DAEffectiveDate, setDAEffectiveDate] = useState(null);

    const [errors, setErrors] = useState({});

    const handleDateChange = (newValue, name) => {
        setAddGroupOutletRequest((preValue) => {
            return {
                ...preValue,
                [name] : convertDateFunctionYMD(newValue)
            }
        })
        updateError(name);
    };
    
    const handleChange = (event) => {

        const {name, value} = event.target;
        
        if(name === "region_id"){
            setAddGroupOutletRequest((preValue) => {
                return {
                    ...preValue,
                    'state_id' : "",
                    'city_id' : "",
                    "cluster_id" : "",
                    "tier_id" : ""
                }
            })
            
        }
        setAddGroupOutletRequest(prevState => ({
            ...prevState,
            [name] : value
        }));
        updateError(name)
    };


    const updateError = (name)=>{
        setErrors((preValue) => {
            return {
                ...preValue,
                [name] : ""
            }
        })
    }
    
    useEffect(() => {
        if(localStorage.getItem("selectedId") !== "" && localStorage.getItem("selectedId")!==undefined && getApplicant){
            const EditData = localStorage.getItem("selectedId") ? getApplicant?.filter( (row, index) => row.id == localStorage.getItem('selectedId')) : []
            setAddGroupOutletRequest(EditData[0])
        }        
    },[localStorage.getItem("selectedId")])
    const addRequestAPI = async() => {
        //const data = convertToFormData(addGroupOutletRequest)
        const addOutletResponse = await dispatch(addDealerGroupOutletAPI(addGroupOutletRequest));
        if(addOutletResponse?.error){
            const { message } = addOutletResponse?.payload;
            Object.keys(message).forEach((field) => {
                message[field].forEach((msg) => {
                    //toast.error(`${field}: ${msg}`);
                });
            });
            setErrors(message);
            
        }else{
            showToast('Dealer Outlet added successfully', 1)
            navigate('/users/dealer-outlet')
        }
    
        
    }
    const editRequestAPI = async() => {        
        const editOutletResponse = await dispatch(editDealerGroupOutletAPI(addGroupOutletRequest));

        if(editOutletResponse?.error){
            const { message } = editOutletResponse?.payload;
            Object.keys(message).forEach((field) => {
                message[field].forEach((msg) => {
                });
            });
            setErrors(message);
            
        }else{
            showToast('Dealer Outlet updated successfully', 1)
            dispatch(getGroupOutletAPI());
            navigate('/users/dealer-outlet')
        }


        
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setCurrentErrorField(null);
    
        try {
            await addGroupOutletSchema.validate(addGroupOutletRequest, { abortEarly: false });
            setErrors({});
            const selectedId = localStorage?.getItem("selectedId");
            if (selectedId && selectedId !== "undefined" && selectedId !== "") {
                await editRequestAPI();
            } else {
                await addRequestAPI();
            }
        } catch (error) {
            if (error.name === 'ValidationError') {
                const newErrors = {};
                error.inner.forEach((validationError) => {
                    newErrors[validationError.path] = validationError.message;
                });
                setErrors(newErrors);
                focusNextErrorField(newErrors);
            } else {
                console.error("An error occurred:", error);
            }
        }
    };
    
    const getGroupUser = useSelector((state) => state?.getGroupDealorSliceReducer?.result?.data);
    const [optionsNew, setOptionsNew] = useState([]);
    
    useEffect(() => {
        const getN = getGroupUser?.map((row, index) => {
            return {
                'id' : row.id,
                'label' : row.dealer_group_name
            }; 
        })
        setOptionsNew(getN)
    },[getGroupUser])
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState("");    
    const [subOutletType, setSubOutletType] = useState([])
    useEffect(() => {
        const fil = facilityTypeList.filter(( row, index) =>  row.outlet_t === addGroupOutletRequest?.outlet_type_id);
        setSubOutletType(fil);
    }, [addGroupOutletRequest?.outlet_type_id])

    const [selectedOptions, setSelectedOptions] = useState(subOutletType);
    const handleChangeFacilityType = (event, newValue) => {
        setAddGroupOutletRequest((preValue) => {
            return {
                ...preValue,
                'facility_type_id': event.target.value
            }
        })
        updateError('facility_type_id')
        // Do something with the selected IDs
    };


    


    const [selectedOptionsFacilityCategory, setSelectedOptionsFacilityCategory] = useState();
    const handleChangeFacilityCategory = (event, newValue) => {
    //     const filteredValue = newValue.filter((option) => !option.isUsed);
    // setSelectedOptionsFacilityCategory(filteredValue);
        setSelectedOptionsFacilityCategory(newValue);
        const selectedIds = newValue.map(option => option.facility_category);
        setAddGroupOutletRequest((preValue) => {
            return {
                ...preValue,
                'facility_category': selectedIds
            }
        })
        updateError('facility_category')
    }
    const handleChangeOutletType = (event) => {
        const {name, value} = event.target;
        setAddGroupOutletRequest(prevState => ({
            ...prevState,
            [name] : value,
            'facility_type_id':""
        }));
        setSelectedOptions([])
        updateError(name)
      };

    

    useEffect(() => {
        if (!getGroupUser && isInitialRender.current) {
          dispatch(getGroupDealerApi());
          //isInitialRender.current = false;
        }
      }, [getGroupUser]);

      useEffect(() => {
        if (!getApplicant && isInitialRender.current) {
          dispatch(getGroupOutletAPI());
          isInitialRender.current = false;
        }
      }, [getApplicant]);
    
    //Edit code goes here
    useEffect(() => {

        const defaultValue = optionsNew?.find(option => option.id === addGroupOutletRequest?.dealer_group_id);
        setValue(defaultValue || null);

        setLeaseExpiryDateShowRoom(addGroupOutletRequest?.lease_expiry_date_showroom ? dayjs(addGroupOutletRequest?.lease_expiry_date_showroom) : null);
        setLeaseExpiryDateWorkShop(addGroupOutletRequest?.lease_expiry_date_workshop ? dayjs(addGroupOutletRequest?.lease_expiry_date_workshop) : null);
        setStartOfOperationTargetDateShowroom(addGroupOutletRequest?.start_of_operation_target_date_showroom ? dayjs(addGroupOutletRequest?.start_of_operation_target_date_showroom) : null);
        setStartOfOperationTargetDateWorkshop(addGroupOutletRequest?.start_of_operation_target_date_workshop ? dayjs(addGroupOutletRequest?.start_of_operation_target_date_workshop) : null);
        setClosureDateShowroom(addGroupOutletRequest?.closure_date_showroom ? dayjs(addGroupOutletRequest?.closure_date_showroom) : null);
        setClosureDateWorkshop(addGroupOutletRequest?.closure_date_workshop ? dayjs(addGroupOutletRequest?.closure_date_workshop) : null);
        setLOADate(addGroupOutletRequest?.LOA_date ? dayjs(addGroupOutletRequest?.LOA_date) : null);
        setLOIDate(addGroupOutletRequest?.LOI_date ? dayjs(addGroupOutletRequest?.LOI_date) : null);
        setDADate(addGroupOutletRequest?.DA_date ? dayjs(addGroupOutletRequest?.DA_date) : null);
        setDAEffectiveDate(addGroupOutletRequest?.DA_effective_date ? dayjs(addGroupOutletRequest?.DA_effective_date) : null);

        if (Array.isArray(addGroupOutletRequest?.facility_category)) {
            const defaultSelectedOptions = facilityCategoryList?.filter(option => addGroupOutletRequest.facility_category.includes(option?.facility_category));
            setSelectedOptionsFacilityCategory(defaultSelectedOptions || []);
        } else {
            setSelectedOptionsFacilityCategory([]);
        }

        setAppointmentDate(addGroupOutletRequest?.appointment_date ? dayjs(addGroupOutletRequest?.appointment_date) : null);
    }, [
        addGroupOutletRequest
    ]);
    useEffect(() => {
        
        if(localStorage.getItem("selectedIdToCreateOutLet")!=null && localStorage.getItem("selectedIdToCreateOutLet")!=undefined)
            
            setAddGroupOutletRequest((preValue) => {
                return {
                    ...preValue,
                    'dealer_group_id' : parseInt(localStorage.getItem("selectedIdToCreateOutLet"))
                }
            })
    },[localStorage.getItem("selectedIdToCreateOutLet")])

    //Focus on error field code start here
    const [currentErrorField, setCurrentErrorField] = useState(null);
    const refs = {
        dealer_group_id: useRef(null),
        region_id: useRef(null),
        state_id: useRef(null),
        cluster_id: useRef(null),
        city_id: useRef(null),
        location: useRef(null),
        tier_id: useRef(null),
        outlet_type_id: useRef(null),
        facility_type_id: useRef(null),
        project_type_showroom: useRef(null),
        project_type_workshop: useRef(null),
        ownership_showroom: useRef(null),
        ownership_workshop: useRef(null),
        showroom_address: useRef(null),
        showroom_pincode: useRef(null),
        showroom_latitude: useRef(null),
        showroom_longitude: useRef(null),
        workshop_address: useRef(null),
        workshop_pincode: useRef(null),
        workshop_latitude: useRef(null),
        workshop_longitude: useRef(null),
        status: useRef(null),
        appointment_date: useRef(null),
        start_of_operation_target_date_showroom: useRef(null),
        start_of_operation_target_date_workshop: useRef(null),
        facility_category: useRef(null),
    };

    const focusNextErrorField = (errors) => {
        for (const field of Object.keys(errors)) {
            if (errors[field] && refs[field] && refs[field].current) {
                setCurrentErrorField(field);
                return;
            }
        }
        setCurrentErrorField(null);
    };

    const smoothScrollTo = (targetElement) => {
        targetElement.scrollIntoView({ behavior: 'smooth' })
        // const start = window.pageYOffset;
        // const end = targetElement.getBoundingClientRect().top + window.pageYOffset;
        // const distance = end - start;
        // const duration = 1000; // Adjust this value to make the scroll slower
        // let startTime = null;

        // const scroll = (currentTime) => {
        //     if (startTime === null) startTime = currentTime;
        //     const timeElapsed = currentTime - startTime;
        //     const run = easeInOutQuad(timeElapsed, start, distance, duration);
        //     window.scrollTo(0, run);
        //     if (timeElapsed < duration) requestAnimationFrame(scroll);
        // };

        // const easeInOutQuad = (t, b, c, d) => {
        //     t /= d / 2;
        //     if (t < 1) return c / 2 * t * t + b;
        //     t--;
        //     return -c / 2 * (t * (t - 2) - 1) + b;
        // };

        // requestAnimationFrame(scroll);
    };
    // Use useEffect to focus on the current error field
    useEffect(() => {
        if (currentErrorField && refs[currentErrorField] && refs[currentErrorField].current) {
            const element = refs[currentErrorField].current;
            element.focus();
        }
    }, [currentErrorField]);

    // Use useEffect to focus on the first error field when errors change
    useEffect(() => {
        focusNextErrorField(errors);
    }, [errors]);
    //Ends here


    //Outlet processing code start here

    //Get all regions
    const regionList = useSelector((state) => state?.getRegionForOutletReducer?.result?.regions);
    useEffect(() => {
       const response = dispatch(getRegionForOutletApi())
    },[dispatch]);

    //get State based on region
    const stateList = useSelector((state) => state?.getStateForOutletReducer?.result?.states);
    useEffect(() => {
        if(addGroupOutletRequest?.region_id){
            const stateRequest = {
                "region_id" : addGroupOutletRequest?.region_id
            }
            const response = dispatch(getStateForOutletApi(stateRequest))
        }
    },[addGroupOutletRequest?.region_id])

    //Get cities based on state
    const cityList = useSelector((state) => state?.getCitiesForOutletReducer?.result?.cities);
    useEffect(() => {
        if(addGroupOutletRequest?.state_id){
            const regionRequest = {
                "state_id" : addGroupOutletRequest?.state_id
            }
            const response = dispatch(getCitiesForOutletApi(regionRequest))
        }
    },[addGroupOutletRequest?.state_id])

    
    const clusterList = useSelector((state) => state?.getClustersForOutletReducer?.result?.clusters);
    const tierList = useSelector((state) => state?.getClustersForOutletReducer?.result?.tiers);
    useEffect(() => {
        if(addGroupOutletRequest?.city_id){
            const cityRequest = {
                "city_id" : addGroupOutletRequest?.city_id
            }
            const response = dispatch(getClusterForOutletApi(cityRequest))
        }
    },[addGroupOutletRequest?.city_id])

    const handleKeyDown = (event) => {
        if (event.key === "Backspace") {
            // Prevent chip deletion using the backspace key
            event.stopPropagation();
            event.preventDefault();
          }
      };
    return (
        <>
            <Body>
      
                 <KeyboardArrowLeftIcon style={{cursor : "pointer"}} onClick={ () => navigate(-1)}/>
    
            <form onSubmit={handleSubmit}>
                
                <Box className={Style?.headerSection}>
                    <Typography
                        color="#fff"
                        fontWeight={500}
                        variant="h6"
                        marginInlineStart={3}
                    >
                        Outlet Information
                    </Typography>
                </Box>
                <Grid container marginBlockStart={2} spacing={2}>
                    <Grid item xs={12} sx={{ marginBlockEnd: "30px" }}>
                        <Grid container spacing={2}>

                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Dealer Group Name <span style={{color:"red"}}>*</span>
                                </Typography>
                                <Autocomplete
                                    
                                    value={value}
                                    name="dealer_group_id"
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                        setAddGroupOutletRequest((preValue) =>{
                                            return {
                                                ...preValue,
                                                'dealer_group_id':newValue ? newValue.id : null
                                            }
                                        })
                                        updateError('dealer_group_id')
                                    }}
                                    fullWidth
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                    }}
                                    id="controllable-states-demo"
                                    options={optionsNew ? optionsNew : []}
                                    getOptionLabel={(option) => option.label}
                                    sx={{ marginTop: "10px" }}
                                    renderInput={(params) => (
                                        <TextField
                                            inputRef={refs.dealer_group_id}
                                            {...params}
                                            placeholder=""
                                        />
                                    )}
                                />
                                <FormHelperText error>
                                        {errors?.dealer_group_id}
                                    </FormHelperText>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Master Code (DMS)
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    value={addGroupOutletRequest?.master_code_DMS}
                                    name="master_code_DMS"
                                    onChange={handleChange}
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    inputProps={{ maxLength: 30 }}
                                />
                                <FormHelperText error>
                                    {errors?.master_code_DMS}
                                </FormHelperText>
                            </Grid>
                            
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                Main Dealer Code 
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    value={addGroupOutletRequest?.main_dealer_code}
                                    name="main_dealer_code"
                                    onChange={handleChange}
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    inputProps={{ maxLength: 30 }}
                                />
                                <FormHelperText error>
                                    {errors?.main_dealer_code}
                                </FormHelperText>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Outlet Code
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    value={addGroupOutletRequest?.outlet_code}
                                    name="outlet_code"
                                    onChange={handleChange}
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    inputProps={{ maxLength: 30 }}
                                />
                                <FormHelperText error>
                                    {errors?.outlet_code}
                                </FormHelperText>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Trade Name <span style={{color:"red"}}>*</span>
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    value={addGroupOutletRequest?.trade_name}
                                    name="trade_name"
                                    onChange={handleChange}
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    error={Boolean(errors.trade_name)}
                                    helperText={errors.trade_name}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Grid>
                            
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  Region  <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.region_id ?  addGroupOutletRequest?.region_id : ""}
                                        onChange={handleChange}
                                        name="region_id"
                                        inputRef={refs.region_id}
                                        sx={{ marginTop: "10px" }}
                                        
                                    >
                                        <MenuItem value="">Select Region</MenuItem>
                                        {regionList?.map((row) => (
                                            <MenuItem key={row.key} value={row.id}>{row.name}</MenuItem>
                                        ))}
                                        {/* <MenuItem value="East">East</MenuItem>
                                        <MenuItem value="West">West</MenuItem>
                                        <MenuItem value="North">North</MenuItem>
                                        <MenuItem value="South">South</MenuItem> */}
                                    </Select>
                                    <FormHelperText error>
                                        {errors?.region_id}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  State  <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.state_id ?  addGroupOutletRequest?.state_id : ""}
                                        onChange={handleChange}
                                        name="state_id"
                                        inputRef={refs.state_id}
                                        sx={{ marginTop: "10px" }}
                                        
                                    >
                                        
                                        <MenuItem value="">Select State</MenuItem>
                                        
                                        {stateList?.map((row) => (
                                            <MenuItem key={row.key} value={row.id}>{row.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>
                                        {errors?.state_id}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  City <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.city_id ?  addGroupOutletRequest?.city_id : ""}
                                        onChange={handleChange}
                                        name="city_id"
                                        sx={{ marginTop: "10px" }}
                                        inputRef={refs.city_id}
                                        
                                    >
                                        <MenuItem value="">Select City</MenuItem>
                                        {cityList?.map((row) => (
                                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>
                                        {errors?.city_id}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  Cluster  <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        inputRef={refs.cluster_id}
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.cluster_id ?  addGroupOutletRequest?.cluster_id : ""}
                                        onChange={handleChange}
                                        name="cluster_id"
                                        sx={{ marginTop: "10px" }}
                                        
                                    >
                                        <MenuItem value="">Select Cluster</MenuItem>
                                        {clusterList?.map((row) => (
                                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>
                                        {errors?.cluster_id}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  Tier <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.tier_id ?  addGroupOutletRequest?.tier_id : ""}
                                        onChange={handleChange}
                                        name="tier_id"
                                        sx={{ marginTop: "10px" }}
                                        inputRef={refs.tier_id}
                                        
                                    >
                                        <MenuItem value="">Select Tier</MenuItem>
                                        {tierList?.map((row) => (
                                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>
                                        {errors?.tier_id}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Location <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    value={addGroupOutletRequest?.location}
                                    name="location"
                                    onChange={handleChange}
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    error={Boolean(errors.location)}
                                    helperText={errors.location}
                                    inputRef={refs.location}
                                    inputProps={{ maxLength: 80 }}
                                />
                                </FormControl>
                            </Grid>

                            
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  Outlet Type <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.outlet_type_id ?  addGroupOutletRequest?.outlet_type_id : ""}
                                        onChange={handleChangeOutletType}
                                        name="outlet_type_id"
                                        sx={{ marginTop: "10px" }}
                                        inputRef={refs.outlet_type_id}
                                        fullWidth
                                        
                                    >
                                        <MenuItem value="">Select Outlet Type</MenuItem>
                                        {outletTypeList.map((row) => (
                                            <MenuItem key={row.id} value={row.id}>{row.outlet_type}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>
                                        {errors?.outlet_type_id}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  Facility Type <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.facility_type_id ?  addGroupOutletRequest?.facility_type_id : ""}
                                        onChange={handleChangeFacilityType}
                                        name="facility_type_id"
                                        sx={{ marginTop: "10px" }}
                                        inputRef={refs.facility_type_id}
                                        
                                    >
                                        <MenuItem value="">Select Facility Type</MenuItem>
                                        {subOutletType.map((row) => (
                                            <MenuItem key={row.id} value={row.id}>{row.facility_type}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>
                                        {errors?.facility_type_id}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  Project Type (Showroom) <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.project_type_showroom ?  addGroupOutletRequest?.project_type_showroom : ""}
                                        onChange={handleChange}
                                        name="project_type_showroom"
                                        sx={{ marginTop: "10px" }}
                                        inputRef={refs.project_type_showroom}
                                        
                                    >
                                        <MenuItem value="">Select Project Type (Showroom)</MenuItem>
                                        <MenuItem value="GF">GF</MenuItem>
                                        <MenuItem value="BF">BF</MenuItem>
                                    </Select>
                                    <FormHelperText error>
                                        {errors?.project_type_showroom}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                Project Type (Workshop) <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.project_type_workshop ?  addGroupOutletRequest?.project_type_workshop : ""}
                                        onChange={handleChange}
                                        name="project_type_workshop"
                                        sx={{ marginTop: "10px" }}
                                        inputRef={refs.project_type_workshop}
                                        
                                    >
                                        <MenuItem value="">Select Project Type (Workshop)</MenuItem>
                                        <MenuItem value="GF">GF</MenuItem>
                                        <MenuItem value="BF">BF</MenuItem>
                                    </Select>
                                    <FormHelperText error>
                                        {errors?.project_type_workshop}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  Ownership (Showroom) <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.ownership_showroom ?  addGroupOutletRequest?.ownership_showroom : ""}
                                        onChange={handleChange}
                                        name="ownership_showroom"
                                        sx={{ marginTop: "10px" }}
                                        inputRef={refs.ownership_showroom}
                                        
                                    >
                                        <MenuItem value="">Select Ownership Showroom</MenuItem>
                                        <MenuItem value="To be Leased">To be Leased</MenuItem>
                                        <MenuItem value="To be Owned">To be Owned</MenuItem>
                                        <MenuItem value="Leased">Leased</MenuItem>
                                        <MenuItem value="Owned">Owned</MenuItem>
                                    </Select>
                                    <FormHelperText error>
                                        {errors?.ownership_showroom}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  Ownership (Workshop) <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.ownership_workshop ?  addGroupOutletRequest?.ownership_workshop : ""}
                                        onChange={handleChange}
                                        name="ownership_workshop"
                                        sx={{ marginTop: "10px" }}
                                        inputRef={refs.ownership_workshop}
                                        
                                    >
                                        <MenuItem value="">Select Ownership Workshop</MenuItem>
                                        <MenuItem value="To be Leased">To be Leased</MenuItem>
                                        <MenuItem value="To be Owned">To be Owned</MenuItem>
                                        <MenuItem value="Leased">Leased</MenuItem>
                                        <MenuItem value="Owned">Owned</MenuItem>
                                    </Select>
                                    <FormHelperText error>
                                        {errors?.ownership_workshop}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Lease Expiry Date (Showroom) 
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]} sx={{ marginTop: "10px",padding: "0px" }}>
                                    <DatePicker
                                        name="lease_expiry_date_showroom"
                                        slotProps={{ textField: { fullWidth: true } }}
                                        value={leaseExpiryDateShowRoom}
                                        onChange={(newValue) => handleDateChange (newValue,'lease_expiry_date_showroom')}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Lease Expiry Date (Workshop) 
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]} sx={{ marginTop: "10px",padding: "0px" }}>
                                    <DatePicker
                                        name="lease_expiry_date_workshop"
                                        slotProps={{ textField: { fullWidth: true } }}
                                        value={leaseExpiryDateWorkShop}
                                        onChange={(newValue) => handleDateChange (newValue,'lease_expiry_date_workshop')}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>

                            
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Showroom Address <span style={{color:"red"}}>*</span>
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.showroom_address}
                                    name="showroom_address"
                                    onChange={handleChange}
                                    error={Boolean(errors.showroom_address)}
                                    helperText={errors.showroom_address}
                                    inputRef={refs.showroom_address}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                Showroom Pincode <span style={{color:"red"}}>*</span>
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.showroom_pincode}
                                    onChange={handleChange}
                                    name="showroom_pincode"
                                    error={Boolean(errors.showroom_pincode)}
                                    helperText={errors.showroom_pincode}
                                    inputRef={refs.showroom_pincode}
                                    inputProps={{ maxLength: 6 }}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Showroom Longitude <span style={{color:"red"}}>*</span>
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.showroom_longitude}
                                    onChange={handleChange}
                                    name="showroom_longitude"
                                    error={Boolean(errors.showroom_longitude)}
                                    helperText={errors.showroom_longitude}
                                    inputRef={refs.showroom_longitude}
                                    inputProps={{ maxLength: 30 }}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Showroom Latitude <span style={{color:"red"}}>*</span>
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.showroom_latitude}
                                    onChange={handleChange}
                                    name="showroom_latitude"
                                    error={Boolean(errors.showroom_latitude)}
                                    helperText={errors.showroom_latitude}
                                    inputRef={refs.showroom_latitude}
                                    inputProps={{ maxLength: 30 }}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                Workshop Address <span style={{color:"red"}}>*</span>
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.workshop_address}
                                    name="workshop_address"
                                    error={Boolean(errors.workshop_address)}
                                    helperText={errors.workshop_address}
                                    onChange={handleChange}
                                    inputRef={refs.workshop_address}
                                    inputProps={{ maxLength: 80 }}
                                />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Workshop Pincode <span style={{color:"red"}}>*</span>
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.workshop_pincode}
                                    name="workshop_pincode"
                                    error={Boolean(errors.workshop_pincode)}
                                    helperText={errors.workshop_pincode}
                                    onChange={handleChange}
                                    inputRef={refs.workshop_pincode}
                                    inputProps={{ maxLength: 6 }}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Workshop Longitude <span style={{color:"red"}}>*</span>
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.workshop_longitude}
                                    onChange={handleChange}
                                    name="workshop_longitude"
                                    error={Boolean(errors.workshop_longitude)}
                                    helperText={errors.workshop_longitude}
                                    inputRef={refs.workshop_longitude}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Workshop Latitude <span style={{color:"red"}}>*</span>
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.workshop_latitude}
                                    onChange={handleChange}
                                    name="workshop_latitude"
                                    error={Boolean(errors.workshop_latitude)}
                                    helperText={errors.workshop_latitude}
                                    inputRef={refs.workshop_latitude}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  Elevation Type
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.elevation_type ?  addGroupOutletRequest?.elevation_type : ""}
                                        onChange={handleChange}
                                        name="elevation_type"
                                        sx={{ marginTop: "10px" }}
                                        
                                        
                                    >
                                        <MenuItem value="">Select Elevation type</MenuItem>
                                        <MenuItem value="SI 2.0 Full">SI 2.0 Full</MenuItem>
                                        <MenuItem value="SI 2.0 Sign">SI 2.0 Sign</MenuItem>
                                        <MenuItem value="Red Cube">Red Cube</MenuItem>
                                    </Select>
                                    
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Showroom Frontage (in ft)
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.showroom_frontage_in_ft}
                                    name="showroom_frontage_in_ft"
                                    onChange={handleChange}
                                    error={Boolean(errors.showroom_frontage_in_ft)}
                                    helperText={errors.showroom_frontage_in_ft}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Showroom Covered Area (in sq ft)
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.showroom_covered_area_in_sq_ft}
                                    name="showroom_covered_area_in_sq_ft"
                                    onChange={handleChange}
                                    error={Boolean(errors.showroom_covered_area_in_sq_ft)}
                                    helperText={errors.showroom_covered_area_in_sq_ft}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Showroom Plot Area (in sq ft)
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.showroom_plot_area_in_sq_ft}
                                    name="showroom_plot_area_in_sq_ft"
                                    onChange={handleChange}
                                    error={Boolean(errors.showroom_plot_area_in_sq_ft)}
                                    helperText={errors.showroom_plot_area_in_sq_ft}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  No of Display Cars 
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.number_of_display_cars ? addGroupOutletRequest?.number_of_display_cars: ""}
                                        onChange={handleChange}
                                        name="number_of_display_cars"
                                        sx={{ marginTop: "10px" }}
                                        MenuProps={{
                                            PaperProps: {
                                            style: {
                                                maxHeight: 48 * 5, // 48px per item, show 5 items before scroll
                                                overflowY: "auto",
                                            },
                                            },
                                        }}
                                        >
                                        <MenuItem value="">Select No of Cars Display</MenuItem>
                                        {Array.from({ length: 20 }, (_, index) => (
                                            <MenuItem key={index} value={index + 1}>
                                            {index + 1}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Workshop Covered Area (in sq ft)
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.workshop_covered_area_in_sq_ft}
                                    name="workshop_covered_area_in_sq_ft"
                                    onChange={handleChange}
                                    error={Boolean(errors.workshop_covered_area_in_sq_ft)}
                                    helperText={errors.workshop_covered_area_in_sq_ft}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Workshop Plot Area (in sq ft)
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.workshop_plot_area_in_sq_ft}
                                    name="workshop_plot_area_in_sq_ft"
                                    onChange={handleChange}
                                    error={Boolean(errors.workshop_plot_area_in_sq_ft)}
                                    helperText={errors.workshop_plot_area_in_sq_ft}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  No of Mech Bays 
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.number_of_mech_bays ?  addGroupOutletRequest?.number_of_mech_bays : ""}
                                        onChange={handleChange}
                                        name="number_of_mech_bays"
                                        sx={{ marginTop: "10px" }}
                                        MenuProps={{
                                            PaperProps: {
                                            style: {
                                                maxHeight: 48 * 5, // 48px per item, show 5 items before scroll
                                                overflowY: "auto", // Enable scrolling
                                            },
                                            },
                                        }}
                                        >
                                        <MenuItem value="">Select No of Mech Days</MenuItem>
                                            {Array.from({ length: 20 }, (_, index) => (
                                                <MenuItem key={index} value={index + 1}>
                                                {index + 1}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    
                                    
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  No of BP Bays
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.number_bp_bays? addGroupOutletRequest?.number_bp_bays: ""}
                                        onChange={handleChange}
                                        name="number_bp_bays"
                                        sx={{ marginTop: "10px" }}
                                        MenuProps={{
                                            PaperProps: {
                                            style: {
                                                maxHeight: 48 * 5, // 48px per item, show 5 items before scroll
                                                overflowY: "auto", // Enable scrolling
                                            },
                                            },
                                        }}
                                        >
                                        <MenuItem value="">Select No of BP Bays</MenuItem>
                                            {Array.from({ length: 20 }, (_, index) => (
                                                <MenuItem key={index} value={index + 1}>
                                                {index + 1}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  EV Dealer (Showroom 11kw) 
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.ev_dealer_showroom_11_KW ?  addGroupOutletRequest?.ev_dealer_showroom_11_KW : ""}
                                        onChange={handleChange}
                                        name="ev_dealer_showroom_11_KW"
                                        sx={{ marginTop: "10px" }}
                                        
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                   
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  EV Dealer (Workshop) 
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.ev_dealer_workshop_11_KW ?  addGroupOutletRequest?.ev_dealer_workshop_11_KW : ""}
                                        onChange={handleChange}
                                        name="ev_dealer_workshop_11_KW"
                                        sx={{ marginTop: "10px" }}
                                        
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                    
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  EV Display Zone 
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.ev_display_zone ?  addGroupOutletRequest?.ev_display_zone : ""}
                                        onChange={handleChange}
                                        name="ev_display_zone"
                                        sx={{ marginTop: "10px" }}
                                        
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                    
                                </FormControl>
                            </Grid>
                            
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  DC Fast Charge
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.dc_fast_charger ?  addGroupOutletRequest?.dc_fast_charger : ""}
                                        onChange={handleChange}
                                        name="dc_fast_charger"
                                        sx={{ marginTop: "10px" }}
                                        
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                    
                                </FormControl>
                            </Grid>
                            
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  Green Dealer 
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.green_dealer ?  addGroupOutletRequest?.green_dealer : ""}
                                        onChange={handleChange}
                                        name="green_dealer"
                                        sx={{ marginTop: "10px" }}
                                        
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                    
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Registered Company Name
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.registered_company_name}
                                    name="registered_company_name"
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                    error={Boolean(errors.registered_company_name)}
                                    helperText={errors.registered_company_name}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   CIN
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.CIN}
                                    name="CIN"
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 21 }}
                                    error={Boolean(errors.CIN)}
                                    helperText={errors.CIN}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Registered Address
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.registered_address}
                                    name="registered_address"
                                    onChange={handleChange}
                                    error={Boolean(errors.registered_address)}
                                    helperText={errors.registered_address}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   PAN
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.PAN}
                                    name="PAN"
                                    onChange={handleChange}
                                    error={Boolean(errors.PAN)}
                                    helperText={errors.PAN}
                                    
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   GST
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.GST}
                                    name="GST"
                                    onChange={handleChange}
                                    error={Boolean(errors.GST)}
                                    helperText={errors.GST}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  Status <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.status ?  addGroupOutletRequest?.status : ""}
                                        onChange={handleChange}
                                        name="status"
                                        sx={{ marginTop: "10px" }}
                                        inputRef={refs.status}
                                        
                                    >
                                        <MenuItem value="">Select </MenuItem>
                                        <MenuItem value="Active">Active</MenuItem>
                                        <MenuItem value="Inactive">Not Active</MenuItem>
                                
                                    </Select>
                                    <FormHelperText error>
                                        {errors?.status}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Appointment Date <span style={{color:"red"}}>*</span>
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        inputRef={refs.appointment_date}
                                        name="appointment_date"
                                        value={appointmentDate}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        onChange={(newValue) => {
                                            handleDateChange (newValue,'appointment_date')
                                            handleDateChange ("",'start_of_operation_target_date_showroom')
                                            handleDateChange ("",'start_of_operation_target_date_workshop')
                                        }}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                                <FormHelperText error>
                                        {errors?.appointment_date}
                                    </FormHelperText>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Start of Operation/ Target Date (Showroom) <span style={{color:"red"}}>*</span>
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        inputRef={refs.start_of_operation_target_date_showroom}
                                        name="start_of_operation_target_date_showroom"
                                        value={startOfOperationTargetDateShowroom}
                                        minDate={appointmentDate ? appointmentDate : null}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        onChange={(newValue) => handleDateChange (newValue,'start_of_operation_target_date_showroom')}
                                     />
                                    </DemoContainer>
                                </LocalizationProvider>
                                <FormHelperText error>
                                        {errors?.start_of_operation_target_date_showroom}
                                    </FormHelperText>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Start of Operation/ Target Date (Workshop) <span style={{color:"red"}}>*</span>
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        inputRef={refs.start_of_operation_target_date_workshop}
                                        name="start_of_operation_target_date_workshop"
                                        value={startOfOperationTargetDateWorkshop}
                                        minDate={appointmentDate ? appointmentDate : null}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        onChange={(newValue) => handleDateChange (newValue,'start_of_operation_target_date_workshop')}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                                <FormHelperText error>
                                        {errors?.start_of_operation_target_date_workshop}
                                    </FormHelperText>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Closure Date (Showroom) 
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        name="closure_date_showroom"
                                        value={closureDateShowroom}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        onChange={(newValue) => handleDateChange (newValue,'closure_date_showroom')}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                               
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Closure Date (Workshop) 
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        name="closure_date_workshop"
                                        value={closureDateWorkshop}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        onChange={(newValue) => handleDateChange (newValue,'closure_date_workshop')}
                                     />
                                    </DemoContainer>
                                </LocalizationProvider>
                                
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  LOA Status
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.LOA_status ?  addGroupOutletRequest?.LOA_status : ""}
                                        onChange={handleChange}
                                        name="LOA_status"
                                        sx={{ marginTop: "10px" }}
                                        
                                    >
                                        <MenuItem value="">Select </MenuItem>
                                        <MenuItem value="Sent to Dealer">Sent to Dealer</MenuItem>
                                        <MenuItem value="Received from Dealer">Received from Dealer</MenuItem>
                                        <MenuItem value="Signed">Signed</MenuItem>
                                        <MenuItem value="Pending">Pending</MenuItem>
                                
                                    </Select>
                                    
                                </FormControl>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   LOA Number
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.LOA_number}
                                    name="LOA_number"
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 80 }}
                                    error={Boolean(errors?.LOA_number)}
                                    helperText={errors?.LOA_number}
                                />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    LOA Date
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]} sx={{ marginTop: "10px",padding: "0px" }}>
                                    <DatePicker
                                        name="LOA_date"
                                        value={LOADate}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        onChange={(newValue) => handleDateChange (newValue,'LOA_date')}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                                
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  LOI Status
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.LOI_status ?  addGroupOutletRequest?.LOI_status : ""}
                                        onChange={handleChange}
                                        name="LOI_status"
                                        sx={{ marginTop: "10px" }}
                                        
                                    >
                                        <MenuItem value="">Select </MenuItem>
                                        <MenuItem value="Sent to Dealer">Sent to Dealer</MenuItem>
                                        <MenuItem value="Received from Dealer">Received from Dealer</MenuItem>
                                        <MenuItem value="Signed">Signed</MenuItem>
                                        <MenuItem value="Pending">Pending</MenuItem>
                                
                                    </Select>
                                    
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   LOI Number
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.LOI_number}
                                    name="LOI_number"
                                    onChange={handleChange}
                                    inoutProps={{ maxLength: 80 }}
                                    error={Boolean(errors?.LOI_number)}
                                    helperText={errors?.LOI_number}
                                />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    LOI Date 
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]} sx={{ marginTop: "10px",padding: "0px" }}>
                                    <DatePicker
                                        name="LOI_date"
                                        value={LOIDate}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        onChange={(newValue) => handleDateChange (newValue,'LOI_date')}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                                
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  DA Status
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addGroupOutletRequest?.DA_status ?  addGroupOutletRequest?.DA_status : ""}
                                        onChange={handleChange}
                                        name="DA_status"
                                        sx={{ marginTop: "10px" }}
                                        
                                    >
                                        <MenuItem value="">Select </MenuItem>
                                        <MenuItem value="Sent to Dealer">Sent to Dealer</MenuItem>
                                        <MenuItem value="Received from Dealer">Received from Dealer</MenuItem>
                                        <MenuItem value="Signed">Signed</MenuItem>
                                        <MenuItem value="Pending">Pending</MenuItem>
                                
                                    </Select>
                                    
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   DA Number
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.DA_number}
                                    name="DA_number"
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 80 }}
                                    error={Boolean(errors?.DA_number)}
                                    helperText={errors?.DA_number}
                                />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    DA Date
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        name="DA_date"
                                        value={DADate}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        onChange={(newValue) => handleDateChange (newValue,'DA_date')}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                                
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    DA Effective Date
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        name="DA_effective_date"
                                        value={DAEffectiveDate}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        onChange={(newValue) => handleDateChange (newValue,'DA_effective_date')}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                                
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Remarks
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    value={addGroupOutletRequest?.remarks}
                                    name="remarks"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Blank
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    //value={addGroupOutletRequest?.trade_name}
                                    name="trade_name"
                                    //onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Blank
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    //value={addGroupOutletRequest?.trade_name}
                                    name="trade_name"
                                   // onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Blank
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                   // value={addGroupOutletRequest?.trade_name}
                                    name="trade_name"
                                    //onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Blank
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    //value={addGroupOutletRequest?.trade_name}
                                    name="trade_name"
                                    //onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Blank
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                   // value={addGroupOutletRequest?.trade_name}
                                    name="trade_name"
                                    //onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Blank
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                   // value={addGroupOutletRequest?.trade_name}
                                    name="trade_name"
                                    //onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Blank
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    //value={addGroupOutletRequest?.trade_name}
                                    name="trade_name"
                                    //onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Blank
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    //value={addGroupOutletRequest?.trade_name}
                                    name="trade_name"
                                    //onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Blank
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    //value={addGroupOutletRequest?.trade_name}
                                    name="trade_name"
                                    //onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Blank
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    //value={addGroupOutletRequest?.trade_name}
                                    name="trade_name"
                                    //onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Blank
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    //value={addGroupOutletRequest?.trade_name}
                                    name="trade_name"
                                    //onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                   Blank
                                </Typography>
                                <TextField
                                    id="input"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    //value={addGroupOutletRequest?.trade_name}
                                    name="trade_name"
                                    //onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom  className={FormStyle?.fieldsetLabel}>
                                  Facility Category <span style={{color:"red"}}>*</span>
                                </Typography>
                                <FormControl fullWidth>
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={facilityCategoryList ? facilityCategoryList : null}
                                    getOptionLabel={(option) => option.facility_category}
                                    sx={{ marginTop: "10px" }}
                                    value={selectedOptionsFacilityCategory ? selectedOptionsFacilityCategory : []}
                                    onChange={handleChangeFacilityCategory}
                                    name="facility_category"
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputRef={refs.facility_category}
                                       
                                        
                                    />
                                    )}
                                />
                                {/* <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={facilityCategoryList || []}
                                    getOptionLabel={(option) => option.facility_category}
                                    sx={{ marginTop: "10px" }}
                                    value={selectedOptionsFacilityCategory || []}
                                    onChange={handleChangeFacilityCategory}
                                    name="facility_category"
                                    filterSelectedOptions
                                    clearIcon={null} // Removes the clear button
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            readOnly: true, // Make input read-only to disable cursor
                                        }}
                                        onKeyDown={handleKeyDown} // Prevent backspace from deleting chips
                                        />
                                    )}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                        <Chip
                                            key={option.id}
                                            label={option.facility_category}
                                            {...getTagProps({ index })}
                                            onDelete={
                                            option.isUsed
                                                ? undefined // Disable delete for items with isUsed: true
                                                : getTagProps({ index }).onDelete
                                            }
                                            disabled={option.isUsed}
                                        />
                                        ))
                                    }
                                    /> */}
                                
                                <FormHelperText error>
                                    {errors?.facility_category}
                                </FormHelperText>
                                </FormControl>
                            </Grid>


                        </Grid>
                    </Grid>
                </Grid>
                
                
                <Grid container marginBlockStart={2} spacing={2}>
                    <Grid item xs={6} sx={{ marginBlockEnd: "30px" }}>
                            <Button
                                disabled={loading}
                                // fullWidth
                                variant="contained"
                                type="submit"
                                size="large"
                                sx={{
                                marginBlock: "30px !important",
                                height: { lg: "60px", md: "50px", xs: "40px" },
                                fontSize: { lg: "20px", md: "16px", xs: "14px" },
                                }}
                            >
                                {loading ? <CircularProgress sx={{ color: "#ffffff" }} /> : "Submit"}{" "}
                            </Button>
                    </Grid>
                </Grid>
                </form>
            </Body>
        </>
    );
}

export default AddDealorOutlet;
