import {
  Button,
  InputAdornment,
  Stack,
  Typography,
  TextField,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useRef, useState } from "react";
import Body from "../../components/Body";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "../reusable/DataTable";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getemailTempleteApi } from "../../store/dealorOnBoarding/emailTemplete";
import ExportExcel from "../Excelexport";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import BulkUpload from "../reusable/BulkUpload";
import { downloadDealerGroupData, getGroupDealerApi } from "../../store/dealerGroup/getGroupDealer";
import { deleteDealerGroupApi } from "../../store/dealerGroup/addGroup";
import { bulkDealorUploadApi } from "../../store/dealerGroup/bulkDealerUpload";
import { copyEmailTemplate } from "../../store/dealerGroup/copyEmailTemplete";
import { actions, addInviteApi } from "../../store/dealerGroup/addInvite";
import { getInviteApi } from "../../store/dealerGroup/getInvite"
import { sendInvite } from "../../store/dealerGroup/sendInvite";
import dayjs from 'dayjs';
import instance from "../../config/authAxios";
import { showToast } from "../../toast/toast";
import ButtonStyles from "../../styles/buttons.module.scss";
import formStyles from "../../styles/field.module.scss";
import { convertDateFunction, convertDateFunctionYMD } from "../../utils/dateFormater";
import DeleteConfirmation from "../reusable/DeleteConfirmation";
import DeleteConfirmationModal from "../reusable/DeleteConfirmationModal";
import downloadFile from '../../assets/downloadSheetExample/Dealer_Group_Data.xlsx';
import ClearIcon from '@mui/icons-material/Clear';
import GroupIcon from '@mui/icons-material/Group';

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [defaultSelected,setDefaultSelected] = useState([]);
  const id = useLocation().state?.id;
  const [age, setAge] = useState("");
  const { updateInviteApiData } = actions;
  const getGroupUser = useSelector((state) => state?.getGroupDealorSliceReducer?.result?.data);
  const getAllEmailTemplete = useSelector((state) => state?.getEmailTempleteReducer);
  const [getEmailTemplete, setEmailTemplateData]=useState([]);  
  const [dealerGroupData, setDealerGroupData] = useState(getGroupUser ? getGroupUser : []);
  const copyTempleteId = useSelector((state) => state?.copyEmailTemplateReducer?.kinUserData?.id);
  const [selectedId, setSelectedId] = useState()
  const [selectedRows, setSelectedRows] = useState([]);
  const [outletCreateButtonEnabled, setOutletCreateButtonEnabled] = useState(false);
  const [rowsData, setRowsData] = useState();
  const inputRef = useRef(null);
  const [emailTemplate, setEmailTemplate] = React.useState([]);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [sendEmailEnabled, setSendEmailEnabled] = useState(false);
  const [dataDownload, setDataDownload] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  localStorage.removeItem("selectedId");
  localStorage.removeItem("selectedIdToCreateOutLet");
  const today = dayjs();
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState();
  useEffect(() => {
    if (copyTempleteId) {
      setSelectedId(copyTempleteId)
    }
  }, [copyTempleteId])

const [isRevised, setIsRevised] = useState(false)
const [inviteResponse, setInviteResponse] = useState([])
  async function editSaveInvite(id ,data){
    setIsRevised(true)
    try {
      const response = await instance.patch(`/api/v1/super_admin/invites?id=${id}`, data,
          { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
      )
      setInviteResponse(response)
      setStartDate(dayjs(response?.data?.data?.start_date))
      setEndDate(dayjs(response?.data?.data?.end_date))
      setSelectedTemplateId(response?.data?.data?.copy_email_template?.id)
      //setEmailTemplateData(response?.data?.data?.copy_email_template)
      setSelectedEmailTemplate(response?.data?.data?.copy_email_template)
      
      return response?.data
  } catch (error) {

  }
  }
  useEffect(() => {
    if(rowsData && inviteResponse) {
      const defaultIds = []
      for(const data of rowsData) {
        if(inviteResponse?.data?.data?.user?.some((user) => user === data.email)) {
          if(!selectedRows.some((user) => user === data.id)) {
            if(defaultIds.includes(data.id) === false){
              defaultIds.push(data.id)
            }        
          }         
        }
      }
      setDefaultSelected(defaultIds)
    }
  },[rowsData, inviteResponse])
  useEffect(() => {
    if (id) {
      editSaveInvite(id, {})
    }
}, [id]);


  const columns = [
    { field: "firstName", headerName: "Dealer Group Name", flex: 1 },
    { field: "lastName", headerName: "DP Name",flex: 1 },
    { field: "email", headerName: "Dealer Principal Email", flex: 1},
    { field: "phone", headerName: "Dealer Principal Mobile", flex: 1 },
    { field: "main_city", headerName: "Main City",width: 120 },
    { field: "outlet_count", headerName: "Total Outlet",width: 120 },
    // { field: "region", headerName: "Region" },
    // {field: "actions",headerName: "Actions",},
  ];

  const navigateToSavedInvite = () => {
    navigate("/dealer/savedInvite");
  }

  const handleNavigate = () => {
    navigate("/users/dealer/add-group");
  };
  const handleChange = (event) => {
    setEmailTemplate(event.target.value);
  };

  const editUser = () => {
    navigate("/users/dealer/add-group");
  };

  const deleteUser = async (id) => {
    setOpenModal(true);
    setDeleteId(id)
  };

  const handleCloseModal = () => {
      setOpenModal(false);
  };

  const handleConfirmDelete = async() => {
      const dataTodelete = {
          "id": deleteId
      }
      const deleteData = await dispatch(deleteDealerGroupApi(deleteId));
      if(deleteData?.error){
        const { message } = deleteData?.payload;
      }else{
        showToast(deleteData?.payload?.message, 1);
        dispatch(getGroupDealerApi());
        setDeleteId(null)
        handleCloseModal()
      }
  };
    
  const datePickerStyles = {
    "& .PrivatePickerRoot-picker": {
      fontSize: "0.75rem",
    },
    "& .PrivatePickerRoot-input": {
      height: "32px",
    },
  };

  const handleBulkUpload = () => {
    inputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validExtensions = [".xlsx", ".xls"];
      const isValidExtension = validExtensions.some((ext) =>
        file.name.toLowerCase().endsWith(ext)
      );
      if (!isValidExtension) {
        alert("Please upload a valid Excel file (.xlsx or .xls)");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      const uploadeData = dispatch(bulkDealorUploadApi(formData));
      if (uploadeData?.payload?.status === "success") {
        dispatch(getGroupDealerApi());
      }
    }
  };

  useEffect(() => {
    dispatch(getGroupDealerApi());
  }, []);

  const [searchText, setSearchText] = useState();
  const handleChangeSearch = (e) => {
    e.preventDefault();
    const searchTerm = e.target.value.toLowerCase();
    setSearchText(searchTerm);
  };

  const searchData = (searchText) => {
    if (!searchText) {
      setDealerGroupData(getGroupUser);
      return;
    }
    const filteredData = getGroupUser.filter((row) => {
      const dpName = (row.dp_name || "").toLowerCase();
      const firstName = (row.dealer_group_name || "").toLowerCase();
      const email = (row.dealer_priciple_email_address || "").toLowerCase();
      const main_city = (row.main_city || "").toLowerCase();
      const phone = String(row.dealer_principle_mobile_number);

      return (
        dpName.includes(searchText) ||
        firstName.includes(searchText) ||
        email.includes(searchText) ||
        main_city.includes(searchText) ||
        phone.includes(searchText)
      );
    });

    setDealerGroupData(filteredData);
  }
  useEffect(() => {
    searchData(searchText)
  },[searchText])

  useEffect(() => {
    if (Array.isArray(getGroupUser) && getGroupUser.length > 0) { 
     
      const dataDownload = getGroupUser.map((row) => {
        let brandValues = row?.brands;
        let hyundaiPresent = brandValues?.includes("Hyundai") || false;
        let mahindraPresent = brandValues?.includes("Mahindra") || false;
        let toyotaPresent = brandValues?.includes("Toyota") || false;
        let hondaPresent = brandValues?.includes("Honda") || false;
        let tataPresent = brandValues?.includes("Tata") || false;
        let jlrPresent = brandValues?.includes("JLR") || false;
        let bydPresent = brandValues?.includes("BYD") || false;
        let porschePresent = brandValues?.includes("Porsche") || false;
        let volvoPresent = brandValues?.includes("Volvo") || false;
        let hmsiPresent = brandValues?.includes("HMSI") || false;
        let heroPresent = brandValues?.includes("Hero") || false;
        let suzukiPresent = brandValues?.includes("Suzuki") || false;
        let renaultPresent = brandValues?.includes("Renault") || false;
        let nissanPresent = brandValues?.includes("Nissan") || false;
        let vwPresent = brandValues?.includes("VW") || false;
        let skodaPresent = brandValues?.includes("Skoda") || false;
        let fordPresent = brandValues?.includes("Ford") || false;
        let mgPresent = brandValues?.includes("MG") || false;
        let citreonPresent = brandValues?.includes("Citreon") || false;
        let jeepPresent = brandValues?.includes("Jeep") || false;
        let bmwPresent = brandValues?.includes("BMW") || false;
        let audiPresent = brandValues?.includes("Audi") || false;
        let mercedesPresent = brandValues?.includes("Mercedes") || false;
        let rrPresent = brandValues?.includes("RR") || false;
        let otherPresent = brandValues?.includes("Others") || false;
        let bajajPresent = brandValues?.includes("Bajaj") || false;
        let tvsPresent = brandValues?.includes("TVS") || false;
        let tata_cvPresent = brandValues?.includes("Tata CV") || false;
        
        let businessBackground = row?.business_background;
        let w4Present = businessBackground?.includes("4w") || false;
        let w2Present = businessBackground?.includes("2w") || false;
        let cvPresent = businessBackground?.includes("cv") || false;
        let othersPresent = businessBackground?.includes("others") || false;
   
        return {
          "Dealer Group Name": row.dealer_group_name,
          "DP name": row.dp_name,
          "Dealer Principal Email": row.dealer_priciple_email_address,
          "Dealer Principal Mobile": row.dealer_principle_mobile_number,
          "Main City": row.main_city,
          "Total Outlet": row.outlet_count,
          "Hyundai": hyundaiPresent ? "yes" : "no",
          "Tata": tataPresent ? "yes" : "no",
          "Mahindra": mahindraPresent ? "yes" : "no", 
          "Honda": hondaPresent ? "yes" : "no",
          "Toyota": toyotaPresent ? "yes" : "no",
          "JLR": jlrPresent ? "yes" : "no",
          "BYD": bydPresent ? "yes" : "no",
          "Porsche": porschePresent ? "yes" : "no",
          "Volvo": volvoPresent ? "yes" : "no",
          "HMSI": hmsiPresent ? "yes" : "no",
          "Hero": heroPresent ? "yes" : "no",
          "Suzuki": suzukiPresent ? "yes" : "no",
          "Renault": renaultPresent ? "yes" : "no",
          "Nissan": nissanPresent ? "yes" : "no",
          "VW": vwPresent ? "yes" : "no",
          "Skoda": skodaPresent ? "yes" : "no",
          "Ford": fordPresent ? "yes" : "no",
          "MG": mgPresent ? "yes" : "no",
          "Citreon": citreonPresent ? "yes" : "no",
          "Jeep": jeepPresent ? "yes" : "no",
          "BMW": bmwPresent ? "yes" : "no",
          "Audi": audiPresent ? "yes" : "no",
          "Mercedes": mercedesPresent ? "yes" : "no",
          "RR": rrPresent ? "yes" : "no",
          "Other": otherPresent ? "yes" : "no",
          "Bajaj": bajajPresent ? "yes" : "no",
          "TVS": tvsPresent ? "yes" : "no",
          "Tata CV": tata_cvPresent ? "yes" : "no",

          "4W": w4Present ? "yes" : "no",
          "2W": w2Present ? "yes" : "no",
          "CV": cvPresent ? "yes" : "no",
          "CNG": othersPresent ? "yes" : "no",
        };
      });
      setDataDownload(dataDownload);
    }
  }, [getGroupUser]);


  
  const handleSelectedRows = (data) => {
    setSelectedRows(data);
    if (data.length === 1) {
      setOutletCreateButtonEnabled(true);
    } else {
      setOutletCreateButtonEnabled(false);
    }
  };
  // const handleStartDateChange = (date) => {
  //   console.log(date,"startData")
  //   setStartDate(date);
  //   setEndDate(null);
  // };

  const [startDateError, setStartDateError] = useState(false);
  const handleStartDateChange = (date) => {
    setEndDate(null);
    // Check if the date is valid
    if (!date || isNaN(new Date(date).getTime())) {
      console.warn("Invalid date provided.");
      setStartDateError(true);  
      return; // Do not proceed if the date is invalid
    }
  
    const selectedDate = new Date(date);
    const selectedYear = selectedDate.getFullYear();
    const currentYear = new Date().getFullYear();
    // Validate the year
    if (selectedYear < currentYear) {
      setStartDateError(true);
      setValidationError(true);
      setValidatonErrorMsg("Please select current date or future date.");
      return; // Do not proceed if the year is not 2025
    }
  
    // If valid, set the start date and reset the end date
    setStartDate(date);
    setStartDateError(false);
    
  };
  

  const [validationError, setValidationError] = useState(false);
  const [validatonErrorMsg, setValidatonErrorMsg] = useState('');
  const handleEndDateChange = (newValue) => {
    if (newValue.$d.toString() === 'Invalid Date') {
      setValidationError(true);
      setValidatonErrorMsg("End date must be greater than start date");
      return;
    }
    setEndDate(newValue);
  };
  
  useEffect(() => {
    if (selectedTemplateId && startDate && endDate && selectedRows.length > 0 && validationError===false) {
      setIsSaveEnabled(false);
    } else {
      setIsSaveEnabled(true);
    }
  }, [startDate, endDate, selectedTemplateId, selectedRows, validationError]);

  useEffect(() => {
    if (selectedTemplateId &&selectedRows.length > 0 ) {
      setSendEmailEnabled(true);
    } else {
      setSendEmailEnabled(false);
    }
  }, [selectedTemplateId, selectedRows, validationError]);

  const navigateToAddOutlet = () => {
    localStorage.setItem("selectedIdToCreateOutLet", selectedRows[0])
    navigate("/users/add-dealer-outlet")
  }
  const extractEmailsFromObjects = (arrayOfObjects, selectedIds) => {
    const filteredObjects = arrayOfObjects?.filter((obj) =>
      selectedIds?.includes(obj.id)
    );
    const filteredEmails = filteredObjects?.map((obj) => obj?.dealer_priciple_email_address);
    return filteredEmails;
  };

  const resetForm = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedTemplateId(null);
    setDefaultSelected([]);
  }
  const [sentInviteLoading, setSentInviteLoading] = useState(false);
  const saveInvite = async () => {
    setIsSaveEnabled(true);
    const filteredEmails = extractEmailsFromObjects(
      dealerGroupData,
      selectedRows
    );

   
    const formattedStartDate= convertDateFunctionYMD(startDate)
    const formattedEndDate= convertDateFunctionYMD(endDate)

    const dataToBeSent = {
      copy_email_template_id: copyTempleteId,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      user: filteredEmails,
    };
    const response = await dispatch(addInviteApi(dataToBeSent));
    showToast(response?.payload?.status,1);
    dispatch(getInviteApi());
    resetForm();
    setIsSaveEnabled(false);
  };
  const [dataToBeSent, setDataToBeSend] = useState({})

  useEffect(() => {
    const filteredEmails = extractEmailsFromObjects(
      dealerGroupData,
      selectedRows
    );
    const temp = {
      copy_email_template_id: copyTempleteId,
      start_date: convertDateFunction(startDate),
      end_date: convertDateFunction(endDate),
      user: filteredEmails,
    };
    setDataToBeSend(temp)
    dispatch(updateInviteApiData(temp))
  }, [startDate, endDate, copyTempleteId, dealerGroupData, selectedRows]);

  useEffect(() => {
    setDealerGroupData(getGroupUser);
  }, [getGroupUser]);

  useEffect(() => {
      const newData =
        dealerGroupData?.map((applicant, index) => ({
          id: applicant.id,
          firstName: applicant.dealer_group_name,
          lastName: applicant.dp_name,
          email: applicant.dealer_priciple_email_address,
          phone: applicant.dealer_principle_mobile_number,
          main_city: applicant.main_city,
          outlet_count: applicant.outlet_count,
          region: applicant.zone,
        })) || [];
      setRowsData(newData);
  }, [dealerGroupData]);


  useEffect(() => {
    dispatch(getemailTempleteApi());
  }, []);

  const handleEmailTemplteChange = (event) => {
    const templateId = event.target.value;
    setSelectedTemplateId(templateId);
    dispatch(copyEmailTemplate({ email_template_id: templateId }));
  };

  
  const handlePreviewEdit = () => {
    let data;
    if (selectedTemplateId !== null) {
        if(isRevised) {
         data = getEmailTemplete;
        }else{
          data = getEmailTemplete?.result?.data.filter((item) => item?.id == selectedTemplateId);
        }
      navigate(`/prev-edit/${selectedId}`, { state: data });

    }
  };

  const saveInvites = async () => {
    const filteredEmails = extractEmailsFromObjects(
      dealerGroupData,
      selectedRows
    );
    const formattedStartDate = startDate ? startDate.toISOString().slice(0, 10) : null;
    const formattedEndDate = endDate ? endDate.toISOString().slice(0, 10) : null;
    const dataToBeSent = {
      copy_email_template_id: copyTempleteId,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      user: filteredEmails,
    };
    const response = await dispatch(addInviteApi(dataToBeSent)).then((data) => {
      let getInviteId = data.payload.data.id;
      handleSendInvite(getInviteId);
      //showToast(data.payload.status,1)
      setStartDate(null);
      setEndDate(null);
      setSelectedTemplateId(null);
    });

  };
  const handleSendInvite = (getInviteId) => {
    const payload = { "invites_model_id": getInviteId }
    dispatch(sendInvite(payload))
      .then((data) => {
        showToast(data.payload.message,1)
      })
      .catch((error) => {
        console.error("Error editing email template:", error);
      });
  }

  const dowanloadExcel = () => {
    const link = document.createElement('a');
      link.href = downloadFile;
      link.download = 'dealerGroupExample.xlsx'; // Optional, defaults to file name
      link.click();
  }

  const exportToExcel = async () => {
    try{
      await dispatch(downloadDealerGroupData(selectedRows)).then((data) => {
        resetForm();
      })

    }catch(error){
      console.log(error)
    }
    
  }
  useEffect(() => {
    if (startDate && endDate && endDate.isBefore(startDate)) {
      setValidationError(true);
      setIsSaveEnabled(true)
    } else {
      setValidationError(false);
      //setIsSaveEnabled(false)
    }
  },[startDate,endDate])

  useEffect(() => {
    
    if(isRevised){
      setEmailTemplateData(selectedEmailTemplate)
    }else{
      setEmailTemplateData(getAllEmailTemplete)
    }
  },[getAllEmailTemplete, isRevised, selectedEmailTemplate])


  return (
    <>
      <Body>
        <Grid container alignItems="center" flexWrap={"wrap"} spacing={1}>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Stack
              direction="row"
              spacing={1}
              sx={{ my: 2 }}
              alignItems="center"
            >
              <GroupIcon/>
              <Typography variant="h6" className="pageTitle">Dealer Group</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9}>
            <Grid
              container
              flexWrap={"nowrap"}
              spacing={1}
              justifyContent={"end"}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{
                  marginRight: {
                    md: 0.5,
                    sm: 0,
                  },
                }}
              >
                <TextField className={formStyles.smallInputField}
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" onClick={() => setSearchText("")}>
                          { searchText?.length > 0 ? <ClearIcon style={{cursor:"pointer"}} /> : ""}
                      </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end" onClick={() => setSearchText("")}>
                            { searchText?.length > 0 ? <ClearIcon style={{cursor:"pointer"}} /> : ""}
                        </InputAdornment>
                    ),
                  }}
                  placeholder="Search by DG Name, DP Name, Email, Phone, City"
                  value={searchText}
                  onChange={handleChangeSearch}
                />
              </Grid>
              <Grid item sm="auto">
                <Button variant="contained" onClick={handleNavigate} className={ButtonStyles.small}>
                  Add Dealer Group
                </Button>
              </Grid>
              <Grid item sm="auto">
                {" "}
                <input
                  type="file"
                  ref={inputRef}
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <Button
                  variant="contained"
                  onClick={() => setBulkUploadModal(true)} className={ButtonStyles.small}
                >
                  Bulk Upload Dealer
                </Button>
                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          marginY={0.5}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={"auto"}
            md={"auto"}
            sx={{ flexGrow: "1 !important" }}
          >
          </Grid>
        </Grid>
        

        <Grid item xs={12} sm={12} md={12} mb={2}>
          <Button
            variant="outlined"
            className="white-btn" sx={{fontSize:"12px !important",paddingBlock:"13px !important"}}
            onClick={navigateToSavedInvite}
          >
            My Saved Invites
          </Button>
                  
          {outletCreateButtonEnabled && (
            // <Button
            //   variant="outlined"
            //   href="#"
            //   size="large"
            //   sx={{ py: "12px", background: "#fff", fontWeight: "bold", ml: 2 }}
            //   onClick={navigateToAddOutlet}
            // >
            //   Create Outlet
            // </Button>
                   <Button
                   variant="outlined"
                   className="white-btn" sx={{fontSize:"12px !important",paddingBlock:"13px !important",ml: 1}}
                   onClick={navigateToAddOutlet}
                 >
                   Create Outlet
                 </Button>
          )}
        </Grid>

        
        <Grid container>
          <Grid item xs={12} sx={{ overflowX: "auto", widt: "100%" }}>
            <DataTable
              data={rowsData ? rowsData : []}
              columns={columns}
              deleteUser={deleteUser}
              editUser={editUser}
              type="dealerGroup"
              handleSelectedRows={handleSelectedRows}
              selectedRowss={defaultSelected}
            />
          </Grid>
          <Grid container spacing={1} my={2}>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2} marginBlockEnd={3}>
                <Grid item xs="6">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DemoItem label="Start Date">
                        <DesktopDatePicker className={formStyles.datePickerSmall}
                          value={startDate}
                          minDate={today}
                          onChange={handleStartDateChange}
                          renderInput={(params) => <input {...params} />}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs="6">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DemoItem label="End Date">
                        <div style={datePickerStyles}>
                          <DesktopDatePicker className={formStyles.datePickerSmall}
                            value={endDate}
                            minDate={startDate ? startDate : today}
                            disabled={startDate &&  !startDateError ? false : true}
                            onChange={handleEndDateChange}
                            renderInput={(params) => <input {...params} />}
                          />
                          
                        </div>
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                
              </Grid>
              {validationError && (
                            <Typography variant="caption" color="error">
                              {validatonErrorMsg}
                            </Typography>
                          )}
            </Grid>

            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: "flex",
                flexFlow: "row wrap",
                gap: "10px",
                alignItems: "center",
                justifyContent: "end",
                marginBlockstart: "20px",
              }}
            >
              <Button
                onClick={exportToExcel}
                variant="outlined" className={ButtonStyles.download}
            >
                <svg
                    width="17"
                    height="21"
                    viewBox="0 0 17 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_17_47906)">
                        <path
                            d="M15.5832 11.375C15.4423 11.375 15.3071 11.4303 15.2075 11.5288C15.1079 11.6272 15.0519 11.7608 15.0519 11.9V14C15.0515 14.232 14.9581 14.4543 14.7922 14.6183C14.6262 14.7823 14.4012 14.8746 14.1665 14.875H2.83317C2.59846 14.8746 2.37347 14.7823 2.2075 14.6183C2.04153 14.4543 1.94813 14.232 1.94775 14V11.9C1.94775 11.7608 1.89178 11.6272 1.79215 11.5288C1.69253 11.4303 1.5574 11.375 1.4165 11.375C1.27561 11.375 1.14048 11.4303 1.04085 11.5288C0.941225 11.6272 0.885254 11.7608 0.885254 11.9V14C0.885629 14.5104 1.09098 14.9998 1.4562 15.3608C1.82142 15.7217 2.31667 15.9246 2.83317 15.925H14.1665C14.683 15.9246 15.1783 15.7217 15.5435 15.3608C15.9087 14.9998 16.114 14.5104 16.1144 14V11.9C16.1144 11.7608 16.0585 11.6272 15.9588 11.5288C15.8592 11.4303 15.7241 11.375 15.5832 11.375Z"
                            fill="black"
                        />
                        <path
                            d="M8.49997 0.875C8.35908 0.875 8.22395 0.930312 8.12432 1.02877C8.02469 1.12723 7.96872 1.26076 7.96872 1.4V10.08L4.58997 7.2968C4.48133 7.21161 4.34328 7.17178 4.20532 7.18583C4.06737 7.19988 3.94044 7.26668 3.85165 7.37197C3.76286 7.47726 3.71926 7.61269 3.73016 7.74932C3.74106 7.88595 3.80559 8.01296 3.90997 8.1032L8.15997 11.6032C8.18571 11.6215 8.21324 11.6372 8.24214 11.6501C8.26306 11.6636 8.28483 11.6757 8.3073 11.6865C8.36828 11.7119 8.43379 11.725 8.49997 11.725C8.56615 11.725 8.63166 11.7119 8.69264 11.6865C8.71512 11.6757 8.73688 11.6636 8.75781 11.6501C8.78671 11.6372 8.81423 11.6215 8.83997 11.6032L13.09 8.1032C13.1944 8.01296 13.2589 7.88595 13.2698 7.74932C13.2807 7.61269 13.2371 7.47726 13.1483 7.37197C13.0595 7.26668 12.9326 7.19988 12.7946 7.18583C12.6567 7.17178 12.5186 7.21161 12.41 7.2968L9.03122 10.08V1.4C9.03122 1.26076 8.97525 1.12723 8.87562 1.02877C8.77599 0.930312 8.64087 0.875 8.49997 0.875Z"
                            fill="black"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_17_47906">
                            <rect width="17" height="21" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                Download
            </Button>
              {/* <ExportExcel
                excelData={dataDownload}
                fileName={"Excel Export"}
              /> */}

              <input
                type="file"
                ref={inputRef}
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              <Button className={ButtonStyles.download}
                onClick={handleBulkUpload}
                variant="outlined"
                // sx={{
                //   background: "#D7EDFE",
                //   border: "1px solid #B2D3ED",
                //   color: "#05141F",
                //   "& svg": {
                //     marginRight: "5px",
                //   },
                //   "&:hover": {
                //     background: "#000",
                //     color: "#fff",
                //     "& svg": {
                //       "& path": {
                //         fill: "white !important",
                //       },
                //     },
                //   },
                // }}
              >
                {" "}
                <svg
                  width="16"
                  height="23"
                  viewBox="0 0 16 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.35294 1.54762C1.57324 1.54762 0.941176 2.25117 0.941176 3.11905V19.881C0.941176 20.7488 1.57324 21.4524 2.35294 21.4524H13.6471C14.4267 21.4524 15.0588 20.7488 15.0588 19.881V8.2732C15.0588 7.81446 14.8788 7.37863 14.5658 7.08007L9.16358 1.92594C8.90777 1.68181 8.58183 1.54762 8.24489 1.54762H2.35294ZM0 3.11905C0 1.67259 1.05345 0.5 2.35294 0.5H8.24489C8.80649 0.5 9.34965 0.723646 9.77609 1.13052L15.1784 6.28467C15.6999 6.78224 16 7.50864 16 8.2732V19.881C16 21.3274 14.9465 22.5 13.6471 22.5H2.35294C1.05345 22.5 0 21.3274 0 19.881V3.11905Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.4707 5.21256V1.5459H9.41188V5.21256C9.41188 6.08044 10.044 6.78399 10.8236 6.78399H15.0589V7.83161H10.8236C9.52416 7.83161 8.4707 6.65902 8.4707 5.21256Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.7648 18.3098H4.23535V17.2622H11.7648V18.3098Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.47096 9.4043V15.1662H7.52979V9.4043H8.47096Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.6673 9.03233C7.85102 8.82776 8.14899 8.82776 8.3328 9.03233L11.1563 12.1751L10.4908 12.916L8.00001 10.1435L5.50927 12.916L4.84375 12.1751L7.6673 9.03233Z"
                    fill="black"
                  />
                </svg>
                Upload
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} marginTop={4} marginBottom={6}>
            <hr sx={{ BorderBottomColor: "#000", Opacity: "0.2" }} />
          </Grid>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs={12} md={5} gap={1}>
              <Box
                fullWidth
                display={"flex"}
                justifyContent={"space-between"}
                gap={1}
              >
                <FormControl style={{width:"calc(100% - 154px)"}}>
                  {age ? null : <InputLabel id="select-label"></InputLabel>}
                  <Select
                      className={formStyles.selectMedium}
                      value={selectedTemplateId}
                      name="emailTemplate"
                      placeholder="Select"
                      onChange={handleEmailTemplteChange}
                      disabled={isRevised}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {isRevised ? (
                        
                          <MenuItem key={getEmailTemplete?.id} value={getEmailTemplete?.id}>
                            {getEmailTemplete?.subject}
                          </MenuItem>
                      
                      ) : (
                        getEmailTemplete?.result?.data?.map((template) => (
                          <MenuItem key={template.id} value={template.id}>
                            {template.subject}
                          </MenuItem>
                        ))
                      )}
                    </Select>


                </FormControl>
                <Button sx={{paddingInline:"20px !important"}} onClick={saveInvites} className="black-btn" disabled={!sendEmailEnabled}> Send email</Button>
              </Box>
              {emailTemplate !== "" && <>
                <Box fullWidth marginBlockStart={0.5}>
                  <Button
                    variant="text"
                    size="small"
                    sx={{ color: "#1672B6", fontSize: "12px" }}
                    onClick={handlePreviewEdit}
                    disabled={!sendEmailEnabled}
                  >
                    {" "}
                    Preview & Edit
                  </Button>
                </Box>
              </>}
            </Grid>
            {/* <Grid item xs={12} md={5} gap={1}>
              <Box
                fullWidth
                display={"flex"}
                justifyContent={"space-between"}
                gap={1}
              >
                <FormControl style={{width:"calc(100% - 139px)"}}>
                  {age ? null : <InputLabel id="select-label"></InputLabel>}
                  <Select className={formStyles.selectMedium}
                    value={age}
                    placeholder="Select"
                    onChange={handleChange}
                    disabled
                  >
                    <MenuItem value="Select">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                <Button className="black-btn" sx={{paddingInline:"20px !important"}} disabled> Send SMS</Button>
              </Box>
              <Box fullWidth marginBlockStart={0.5}>
                <Button
                  variant="text"
                  size="small"
                  sx={{ color: "#1672B6", fontSize: "12px" }}
                  disabled
                >
                  {" "}
                  Preview & Edit
                </Button>
              </Box>
            </Grid> */}
          </Grid>
          <Grid container justifyContent="end">
            <Grid item xs={"auto"} gap={2} display={"flex"} marginBlock={5}>
              <Button
              
                className="white-btn"
                onClick={resetForm}
              >
                Cancel
              </Button>
              <Button
          
               className="black-btn"
                onClick={saveInvite}
                disabled={isSaveEnabled}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Body>
      <DeleteConfirmationModal open={openModal} handleClose={handleCloseModal}  handleConfirm={handleConfirmDelete}  deleteType='Dealer Group'/>
      <BulkUpload open={bulkUploadModal} setOpen={setBulkUploadModal} type="dealer" downloadFile={dowanloadExcel}/>
      {/* <DeleteConfirmation open={openDeleteConfirmation} setOpen={setOpenDeleteConfirmation} /> */}
    </>
  );
};

export default Users;
