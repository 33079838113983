import React, { useEffect } from "react";
import { Box, Tooltip, Typography, IconButton, Modal } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { handleDownload } from "./downloadFile";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import pdf from "../../assets/img/pdf.png";
import cad from "../../assets/img/cad.png";
import otherFile from "../../assets/img/file-icon.png";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ViewPdfModal({ open, handleClose, row,fileType }) {
  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <IconButton
          onClick={() => handleClose()}
          sx={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <HighlightOffIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {fileType}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Box>
            <Box className="m-auto p-relative">
            {fileType == "PDF" ? (<>{row?.schematic_pdf.map((pdf) => {
                return (
                  <Box key={pdf.files} className="text-center">
                    <Box className="p-relative">
                      <Tooltip
                        title={pdf?.files}
                        placement="top"
                        className={` uploadedfilename`}
                      >
                        <Typography>{"PDF"}</Typography>
                        <DownloadForOfflineRoundedIcon
                          className="my-auto"
                          onClick={() =>
                            handleDownload(
                              new Date() + "schematic_pdf",
                              pdf?.files
                            )
                          }
                        />
                        <FullscreenIcon
                          className="my-auto"
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_BASE_URL}${pdf?.files}`
                            )
                          }
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                );
              })}</>) : (<>{ row?.schematic_cad.map((pdf) => {
                return (
                    <Box key={pdf.files} className="text-center">
                        <Box className="p-relative">
                            <Tooltip title={pdf?.files} placement="top" className={` uploadedfilename`}>
                                <Typography>{'CAD'}</Typography>
                                <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', pdf?.files)} />
                                <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${pdf?.files}`)} />
                            </Tooltip>
                        </Box>
                    </Box>
                )
            })
            }</>)}
              
            </Box>
          </Box>
        </Typography>
      </Box>
    </Modal>
  );
}
